.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("../assets/img/DSC_7594-modified.png");
  background-position: top center;
  background-position-y: 24%;
  image-rendering: crisp-edges;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 200;
  letter-spacing: 2px;
  padding: 15px 20px;
  background: transparent;
  border: 1px solid rgba(10, 9, 9, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner div {
  width: 100%; /* Ensures the div takes up the full width of its parent */
}

.banner h1 {
  font-size: 44px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 55%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
