.bio {
  padding: 0 0 50px 0;
  position: relative;
}
.bio-bx {
  background: #000000;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.bio h2 {
  font-size: 45px;
  font-weight: 700;
}
.bio p {
  color: #7c7c7c;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.bio-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.bio-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
