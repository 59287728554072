.footer {
  padding: 20px 0px 20px 0px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #634d2a;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.social-icon {
  display: flex; /* Use flexbox layout to align items in a row */
  justify-content: right; /* Center the icons horizontally */
  gap: 5px; /* Creates space between the icons */
}
